import { ErrorCard } from '../components'
import { useFormatMessage } from '../hooks'

function NotFoundPage() {
  const formatMessage = useFormatMessage()
  return (
    <ErrorCard
      title={formatMessage('error.title')}
      text={formatMessage('error.404.text')}
    />
  )
}

NotFoundPage.analytics = {
  name: 'page-not-found',
}

export default NotFoundPage
